.FooterSpoiler-module_spoiler_vRHJ9__Main {
  background-color: var(--bg-block);
  width: 100%;
  max-width: 270px;
  position: relative;
}

.FooterSpoiler-module_spoiler__wrapper_bw6xI__Main {
  cursor: pointer;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.FooterSpoiler-module_spoiler__name_SgHBn__Main {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--text-primary);
  margin-right: 5px;
  overflow: hidden;
}

.FooterSpoiler-module_spoiler__arrow-icon-wrapper_oXdtC__Main {
  align-items: center;
  display: flex;
}

.FooterSpoiler-module_spoiler__arrow-icon-wrapper__icon_7Qvun__Main {
  fill: var(--icons-primary);
}

.FooterSpoiler-module_spoiler__arrow-icon_a-7fn__Main {
  fill: var(--icons-primary);
  width: 16px;
  height: 16px;
  transition: transform .25s, fill .25s;
}

.FooterSpoiler-module_spoiler__arrow-icon--rotated_II-AS__Main {
  transform: rotate(180deg);
}

.FooterSpoiler-module_spoiler__dropdown-container_roIpF__Main {
  flex-direction: column;
  gap: 20px;
  height: 0;
  display: flex;
}

.FooterSpoiler-module_spoiler__dropdown-container--active_k-YwE__Main {
  height: auto;
}

.FooterSpoiler-module_spoiler__dropdown-item_tvxsm__Main {
  white-space: nowrap;
  color: var(--text-secondary);
  text-overflow: ellipsis;
  cursor: pointer;
  display: block;
  overflow: hidden;
}

.FooterSpoiler-module_spoiler__dropdown-item_tvxsm__Main:hover, .FooterSpoiler-module_spoiler__dropdown-item_tvxsm__Main:active {
  color: var(--text-link-active);
}

.FooterSpoiler-module_spoiler__body_06xPB__Main {
  background-color: var(--bg-block);
  border-radius: 10px;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

@media (max-width: 660px) {
  .FooterSpoiler-module_spoiler_vRHJ9__Main {
    max-width: none;
  }

  .FooterSpoiler-module_spoiler__dropdown-container_roIpF__Main {
    height: auto;
  }

  .FooterSpoiler-module_spoiler__dropdown-container--active_k-YwE__Main {
    height: 0;
  }

  .FooterSpoiler-module_spoiler__wrapper_bw6xI__Main {
    margin-bottom: 0;
  }

  .FooterSpoiler-module_spoiler__dropdown-item_tvxsm__Main:first-child {
    margin-top: 20px;
  }

  .FooterSpoiler-module_spoiler__arrow-icon_a-7fn__Main {
    fill: var(--icons-primary);
    width: 16px;
    height: 16px;
    transition: transform .25s, fill .25s;
    transform: rotate(180deg);
  }

  .FooterSpoiler-module_spoiler__arrow-icon--rotated_II-AS__Main {
    transform: rotate(0);
  }
}

.StoreIcons-module_store-icons_tN7OK__Main {
  background-color: var(--bg-white-opacity-5);
  border-radius: 10px;
  align-items: center;
  gap: 10px;
  width: 150px;
  height: 50px;
  padding: 20px 10px;
  display: flex;
}

.StoreIcons-module_store-icons_tN7OK__Main .StoreIcons-module_store-icon_-ZKUE__Main {
  fill: var(--icons-primary);
}

.StoreIcons-module_store-icons--download_3D6zU__Main {
  white-space: nowrap;
  color: var(--text-secondary);
}

.StoreIcons-module_store-icons--market_6K6q1__Main {
  white-space: nowrap;
  color: var(--text-primary);
}

@media (max-width: 390px) {
  .StoreIcons-module_store-icons_tN7OK__Main {
    gap: 6px;
    padding: 20px 7px;
  }
}

@media (max-width: 360px) {
  .StoreIcons-module_store-icons_tN7OK__Main {
    gap: 3px;
    padding: 20px 5px;
  }
}

